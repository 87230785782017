:root {
  --primary-color: #198942;
  --hover-color: #27a757;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none !important;
}

.primary-color {
  background-color: var(--primary-color);
}

.primary-text-color {
  color: var(--primary-color) !important;
}

.logoimg {
  width: 200px;
}

.cursor-pointer {
  cursor: pointer;
}

/****** NAVBAR ******/

.logo {
  width: 50px;
}
.primary-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.5rem 0.8rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
}

.primary-button:hover {
  background-color: var(--hover-color);
}

.nav-link-margin {
  margin-left: 0.7rem;
}

.nav-text {
  color: #000 !important;
}

.nav-text:hover {
  color: var(--primary-color) !important;
}

/****** FEATURED SECTION ******/

.icon-container {
  border: 6px solid var(--primary-color);
  border-radius: 50%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

/****** WHY CHOOSE US *****/

.why-choose-us {
  position: relative;
  width: 100%;
  height: 70vh;
  background: url("/public/img/demo1.png") no-repeat center center;
  background-size: cover;
  color: white;
}

.why-choose-us .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
}

.why-choose-us .container {
  position: relative;
  z-index: 1;
}

/****** SLIDER ******/

.main-slider .carousel-item {
  height: 80vh;
}

.carousel-image-container {
  position: relative !important;
}

.carousel-image-container .overlay {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background: rgba(0, 0, 0, 0.573);
  z-index: 1;
}

.carousel-caption {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 3;
  text-align: center;
  text-shadow: 0px 0px 22px rgba(0, 0, 0, 0.511);
}

.carousel-button {
  backdrop-filter: blur(10px);
  transition: background-color 0.3s ease;
  padding: 0.6rem 2rem !important;
  text-transform: uppercase;
}

.carousel-button:hover {
  background: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.5);
}

/****** HERO ******/
.text-justify {
  text-align: justify;
}

/****** TESTIMONIALS *******/

.testimonial-carousel .carousel-indicators {
  display: none !important;
}

/****** BLOG CARD ******/

.blog-card-img {
  width: 320px;
  height: 200px;
  object-fit: cover;
}

.blog-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.blog-card:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/****** BLOG POSTS ******/

.subheading-img {
  height: 50vh;
  width: 100%;
  object-fit: cover;
}

.blogpost-img {
  height: 80vh;
  object-fit: cover;
}

/****** MEDIA CARD ******/

.media-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 0 !important;
  cursor: pointer;
}

.media-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.media-border {
  border-left: 5px solid var(--primary-color);
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.media-card .youtube-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: auto;
  pointer-events: none;
}

.blog-card-img {
  width: 320px;
  height: 200px;
  object-fit: cover;
  border-radius: 0 !important;
}

.card-body div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#unicode-bold {
  font-weight: 600;
}

/***** SERVICES ******/
.services-img {
  display: flex;
  justify-content: center;
}
.services-img img {
  height: 7rem;
}

/****** Payment Section ******/
.payment-section {
  height: 80vh;
}

/****** FOOTER ******/
.footerlogo img {
  width: 80px;
}

/****** RESPONSIVENESS ******/

@media (max-width: 992px) {
  .nav-link-margin {
    margin-left: 0;
    margin-top: 0.6rem;
  }
  .main-slider .carousel-item {
    height: 60vh;
  }

  .carousel-control-next-icon {
    margin-right: 20px;
  }

  .carousel-control-prev-icon {
    margin-left: 20px;
  }

  .main-slider .carousel-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .icon-container {
    border: 3px solid var(--primary-color);
  }

  .subheading-img {
    height: 30vh;
    width: 100%;
    padding-top: 20px;
  }

  .blogpost-img {
    height: 30vh;
  }

  .blogpost-section .text-justify {
    text-align: start;
  }

  .carousel-caption {
    width: 70%;
  }

  .payment-center {
    text-align: center;
  }
}
